import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/Romans_Big.jpeg"
import Romans from "../../../images/Romans_Series.jpeg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Romans: Principles"
    SecondText="Check out sermons from our Romans Sermon Series"
  >
    <SEO title="Sermons - Romans: Principles" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="The Struggle is Real"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/the-struggle"
        date="November 28, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Who's Slave Are You?"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/whos-slave"
        date="November 7, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Leveling Up"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/leveling-up"
        date="October 31, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Belonging by Believing"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/belonging"
        date="October 24, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="This is Us"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/this-is-us"
        date="October 17, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Gospel Identity"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/gospel-identity"
        date="October 10, 2021"
        pastor="Robert Smith"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="The Problem and the Cure"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/problem-and-cure"
        date="October 3, 2021"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="The Wrath and Love of God"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/wrath-and-love"
        date="September 26, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Magnum Opus"
        sermonSeries="Romans: Principles"
        sermonLink="/surprise/sermons/magnum-opus"
        date="September 19, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/surprise/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Surprise"
      />
    </Grid>
  </Layout>
)

export default IndexPage
